<div *ngIf="!comments && loading" class="d-flex m-5 justify-content-center">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="comments?.length">
  <mat-toolbar class="bg-primary comment-header p-0 d-flex flex-row">
    <h5 class="px-3">
      <mat-icon
        svgIcon="comment-outline"
        style="margin-bottom: -5px"
      ></mat-icon>
      Comments
      <span *ngIf="parentType === 'FAR'">({{ comments.length }})</span>
      <ng-container *ngIf="commentsAttachmentsDisabled"
        >- Comments have been disabled</ng-container
      >
    </h5>
    <span class="col"></span>
    <button
      [disabled]="loading"
      mat-button
      matTooltip="Refresh"
      matTooltipPosition="left"
      style="color: white"
      [class]="editable ? '' : 'mr-2'"
      (click)="refresh()"
    >
      <ng-container *ngIf="!loading">
        <mat-icon style="margin-bottom: -6px" svgIcon="refresh"></mat-icon>
        <span class="ml-1">Refresh</span>
      </ng-container>
      <mat-spinner
        color="primary"
        *ngIf="loading"
        [diameter]="35"
      ></mat-spinner>
    </button>
    <button
      *ngIf="editable"
      [disabled]="commentsAttachmentsDisabled"
      id="add-new-button"
      mat-button
      class="mr-2"
      style="color: white"
      matTooltip="Add Comment"
      matTooltipPosition="left"
      [appCommentDialog]="this.parentType"
      [parentId]="parentId"
      (commentSaved)="onUpdate($event)"
    >
      <div class="d-flex align-items-center gap-1">
        <mat-icon svgIcon="plus-circle-outline"></mat-icon>
        <span>Add</span>
      </div>
    </button>
  </mat-toolbar>
  <mat-accordion class="pb-2 mt-1 comments-list-accordian" multi>
    <mat-expansion-panel
      class="mat-elevation-z0 comment-panel mx-3"
      togglePosition="before"
      displayMode="flat"
      [expanded]="!commentClosed[comment.id]"
      (closed)="commentClosed[comment.id] = true"
      (opened)="commentClosed[comment.id] = false"
      *ngFor="
        let comment of comments | orderByImpure: ['createdDate'];
        let i = index
      "
    >
      <mat-expansion-panel-header>
        <mat-panel-title
          *ngIf="comment.author"
          class="text-nowrap comment-header-title"
        >
          <app-user-avatar [user]="comment.author"></app-user-avatar>
          <span class="pl-1 pr-3 h6 mb-0 text-primary">{{
            comment.author | fullname
          }}</span>
          <span class="">
            {{ comment.createdDate | date: "MMM d, y, h:mm a" }}
          </span>
        </mat-panel-title>
        <mat-panel-description
          [matTooltip]="comment.comment"
          *ngIf="commentClosed[comment.id] && comment.comment"
          class="text-truncate comment-header-description"
        >
          <span class="text-truncate">
            {{ comment.comment | shorten: 75:"..." }}
          </span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div>
        <app-classification-banner
          class="d-block mb-4"
          [classificationModel]="comment"
        ></app-classification-banner>
        <span class="comment-text">{{ comment.comment }}</span>
      </div>
      <mat-action-row
        *ngIf="editable && comment?.author?.id === currentUserId"
        class="d-flex flex-row justify-content-start comment-actions"
      >
        <button
          mat-button
          [disabled]="commentsAttachmentsDisabled"
          class="button-small"
          [appCommentDialog]="this.parentType"
          [comment]="comment"
          (commentSaved)="onUpdate($event)"
          id="edit-comment-button-{{ i }}"
          color="primary"
        >
          <mat-icon>create</mat-icon> Edit
        </button>
        <ng-container *restrictDelete>
          <button
            *ngIf="deletable"
            mat-button
            class="button-small"
            (click)="removeComment(comment)"
            id="delete-comment-button-{{ i }}"
            color="warn"
          >
            <mat-icon>delete</mat-icon> Delete
          </button>
        </ng-container>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<app-empty-state
  *ngIf="comments && !comments?.length"
  [message]="
    commentsAttachmentsDisabled
      ? 'No Comment Records - Comments have been disabled'
      : 'No Comment Records'
  "
>
  <button
    *ngIf="editable"
    [disabled]="commentsAttachmentsDisabled"
    mat-flat-button
    color="accent"
    id="add-first-comment"
    [appCommentDialog]="this.parentType"
    [parentId]="parentId"
    (commentSaved)="onUpdate($event)"
  >
    <span>Add Comment</span>
  </button>
</app-empty-state>
