import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthService } from '@app/auth/auth.service';
import { AddressComponent } from '@shared/controls/address/address.component';
import { CountrySelectComponent } from '@shared/controls/country-select/country-select.component';
import { AuthEventsDialogDirective } from '@shared/directives/auth-events-dialog.directive';
import { FlagIconDirective } from '@shared/directives/flag-icon.directive';
import { Contact } from '@shared/models/contact.model';
import { Role } from '@shared/models/role';
import { User } from '@shared/models/user.model';
import { CountryPipe } from '@shared/pipes/country.pipe';
import { DefaultPipe } from '@shared/pipes/default.pipe';
import { ContactService } from '@shared/services/contact.service';
import { cloneDeep } from 'lodash';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { finalize, take } from 'rxjs/operators';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { OrganizationFormComponent } from '../organization-form/organization-form.component';
import { AuthEventsDialogComponent } from '@shared/components/auth-events-dialog/auth-events-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { DebounceClickDirective } from '@shared/directives';
@Component({
  selector: 'app-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    DebounceClickDirective,
    AuthEventsDialogComponent,
    AuthEventsDialogDirective,
    ContactFormComponent,
    OrganizationFormComponent,
    AddressComponent,
    CountrySelectComponent,
    CountryPipe,
    DefaultPipe,
    DebounceClickDirective,
    FlagIconDirective,
    MatFormFieldModule,
    MatDialogModule,
    MatSnackBarModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    MatProgressSpinnerModule,
  ],
})
export class ContactDialogComponent implements OnInit {
  public Role = Role;
  user: User | null = this.authService.getUser();
  @ViewChild('contactForm') contactForm: ContactFormComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public contact: Contact | null,
    public contactDialog: MatDialogRef<ContactDialogComponent>,
    private contactService: ContactService,
    public authService: AuthService
  ) {}

  public disabledSubmit: boolean = false;

  ngOnInit(): void {
    this.disabledSubmit = false;
    if (this.contact) {
      if (this.contact.id) this.getContact(this.contact.id);
      else {
        this.contact = {
          nameType: 'person',
          organization: this.contact.organization,
          type: this.contact.type,
        };
      }
    }
  }

  displayResourceDetails(): boolean {
    if (this.user?.roles) {
      if (
        this.user?.roles.includes(Role.sv_admin) &&
        this.contact &&
        this.contact.id
      ) {
        return true;
      }
      return false;
    }
    return false;
  }

  getContact(id: string) {
    this.contactService
      .get(id)
      .pipe(take(1))
      .subscribe((data) => {
        this.contact = cloneDeep(data);
        if (this.contact.groupName) this.contact.nameType = 'group';
        else this.contact.nameType = 'person';
      });
  }

  // Handles changes emitted from from component
  onContactChange(contact: any) {
    this.contact = contact;
  }

  onSubmit() {
    if (this.contact) {
      this.disabledSubmit = true;
      this.contactService
        .save(this.contact)
        .pipe(
          take(1),
          finalize(() => (this.disabledSubmit = false))
        )
        .subscribe((data) => {
          this.contactDialog.close(data);
        });
    }
  }

  static openDialog(
    dialog: MatDialog,
    contact?: Contact
  ): MatDialogRef<ContactDialogComponent> {
    return dialog.open<ContactDialogComponent>(ContactDialogComponent, {
      data: contact,
      width: '750px',
    });
  }
}
