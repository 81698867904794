import { CommonModule } from '@angular/common';
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AppConfigValue } from '@shared/constants/shared.const';
import { AttachmentData } from '@shared/models/attachment-data.model';
import { ForeignVisitor } from '@shared/models/foreign-visitor.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { AlertService } from '@shared/services/alert.service';
import { AppConfigService } from '@shared/services/app-config.services';
import {
  S3_HEADER_SSE,
  S3_HEADER_SSE_ID,
} from '@shared/services/attachment.service';
import { FvAttachmentService } from '@shared/services/fv-attachment.service';
import {
  FileItem,
  FileUploader,
  FileUploaderOptions,
  FileUploadModule,
} from 'ng2-file-upload';
import { ImageCroppedEvent, ImageCropperModule } from 'ngx-image-cropper';
import { WebcamImage, WebcamInitError, WebcamModule } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';

interface ProfilePicSelectData {
  fv: ForeignVisitor;
}

@Directive({
  selector: '[appProfilePicSelect]',
  standalone: true,
})
export class ProfilePicSelectDirective {
  @Input() appProfilePicSelect: ForeignVisitor;

  @Output() appProfilePicChanged = new EventEmitter<AttachmentData | null>();

  constructor(private dialog: MatDialog) {}

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.appProfilePicSelect) return;
    const dialog = ProfilePicSelectComponent.openDialog(this.dialog, {
      fv: this.appProfilePicSelect,
    });
    dialog.afterClosed().subscribe((result) => {
      if (result?.id) return this.appProfilePicChanged.emit(result);
      if (result) return this.appProfilePicChanged.emit(null);
    });
  }
}

@Component({
  selector: 'app-profile-pic-select',
  templateUrl: './profile-pic-select.component.html',
  styleUrls: ['./profile-pic-select.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    FileUploadModule,
    ImageCropperModule,
    WebcamModule,
    FullnamePipe,
  ],
})
export class ProfilePicSelectComponent {
  private readonly configService: AppConfigService = inject(AppConfigService);
  @ViewChild('fileInput') fileInput: ElementRef;

  allowedMimeType = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
    'image/gif',
    'image/tiff',
    'image/webp',
  ];

  @AppConfigValue('commentsAttachmentsDisabled')
  commentsAttachmentsDisabled: boolean = true;

  fileItem: FileItem | null;

  camTrigger: Subject<void> = new Subject<void>();

  webcamImage: WebcamImage | null;

  camCapture = false;

  fileHoverDrop = false;

  uploader: FileUploader;

  camError: WebcamInitError;

  camHeight: number;

  cropHeight: number;

  croppedImage?: string | null;

  attachment: AttachmentData;

  uploading = false;

  fullNamePipe = new FullnamePipe();

  _defaultOptions: FileUploaderOptions;

  constructor(
    public dialogRef: MatDialogRef<ProfilePicSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProfilePicSelectData,
    public attachmentService: FvAttachmentService,
    private alert: AlertService
  ) {
    this._defaultOptions = {
      itemAlias: 'file',
      method: 'PUT',
      allowedMimeType: this.allowedMimeType,
      disableMultipart: true,
      url: '',
    };
    this.uploader = new FileUploader(this._defaultOptions);

    this.uploader.onAfterAddingFile = (fileItem) => this.onAddFile(fileItem);
    this.uploader.onSuccessItem = (_item: FileItem, _response: string) => {
      this.alert.successAlert('Profile picture updated');
      this.dialogRef.close(this.attachment);
    };
    this.uploader.onErrorItem = (_item: FileItem, _response: string) => {
      this.uploading = false;
      this.alert.errorAlert('Error updating profile picture');
      console.log(arguments);
    };

    this.calcHeights();
  }

  @HostListener('window:resize')
  calcHeights() {
    this.camHeight = Math.max(window.innerHeight - 160, 200);
    this.cropHeight = this.camHeight - 55;
  }

  onAddFile(fileItem: FileItem) {
    if (this.uploading) return;
    this.fileItem = fileItem;
  }

  deleteProfilePic() {
    this.alert
      .confirmDelete({
        title: `Confirm Delete Profile Picture`,
        message: `Are you sure you want to delete profile picture?`,
        performAction: () =>
          this.attachmentService.delete(
            this.data.fv.profilePhotoAttachment?.id
          ),
        successMsg: 'Profile picture removed',
      })
      .subscribe(() => this.dialogRef.close(true));
  }

  get triggerObservable(): Observable<void> {
    return this.camTrigger.asObservable();
  }

  imageCapture(): void {
    this.camTrigger.next();
  }

  onImageCapture(webcamImage: WebcamImage) {
    this.webcamImage = webcamImage;
  }

  onCaptureError(error: WebcamInitError): void {
    this.camError = error;
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  clear() {
    this.fileItem = null;
    this.webcamImage = null;
    this.camCapture = false;
    this.croppedImage = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  getCroppedFile() {
    if (!this.croppedImage?.length) return null;
    const arr = this.croppedImage.split(',');
    const mime = arr[0].match(/:(.*?);/)![1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File(
      [u8arr],
      `${this.data.fv.givenName}-${this.data.fv.surname}.png`,
      { type: mime }
    );
  }

  submit() {
    const file = this.getCroppedFile();
    if (!file) return;

    this.uploading = true;

    this.attachment = {
      parentId: this.data.fv.id,
      name: file.name,
      mimeType: file.type,
    };

    this.attachmentService
      .addProfilePic(this.attachment)
      .subscribe((result: any) => {
        const newOptions = { ...this._defaultOptions };
        this.attachment = result;
        this.uploader.clearQueue();
        this.uploader.addToQueue([file]);
        if (
          result.xAmzServerSideEncryption &&
          result.xAmzServerSideEncryptionId
        ) {
          newOptions.headers = [
            { name: S3_HEADER_SSE, value: result.xAmzServerSideEncryption },
            {
              name: S3_HEADER_SSE_ID,
              value: result.xAmzServerSideEncryptionId,
            },
          ];
        }
        newOptions.url = result.presignUrl!;
        this.uploader.setOptions(newOptions);
        this.uploader.uploadAll();
      });
  }

  get hasFile(): boolean {
    return !!this.fileItem || !!this.webcamImage;
  }

  static openDialog(
    dialog: MatDialog,
    data: ProfilePicSelectData
  ): MatDialogRef<ProfilePicSelectComponent> {
    return dialog.open<ProfilePicSelectComponent, ProfilePicSelectData>(
      ProfilePicSelectComponent,
      {
        data,
        width: '600px',
      }
    );
  }
}
