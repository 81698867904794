import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '@app/auth/auth.service';
import { ClassificationBannerComponent } from '@shared/cmt/classification-banner.component';
import { ClassifyShowDirective } from '@shared/cmt/classify-show.directive';
import { ClassifyDirective } from '@shared/cmt/classify.directive';
import { AppConfigValue } from '@shared/constants/shared.const';
import { CommentDialogDirective } from '@shared/directives/comment-dialog.directive';
import { Comment, CommentType } from '@shared/models/comment.model';
import { FullnamePipe } from '@shared/pipes/fullname.pipe';
import { AlertService } from '@shared/services/alert.service';
import { CommentService } from '@shared/services/comment.service';
import { FarCommentService } from '@shared/services/far-comment.service';
import { FVCommentService } from '@shared/services/fv-comment.service';
import { NgPipesModule } from 'ngx-pipes';
import { finalize } from 'rxjs/operators';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { CommentDialogComponent } from './comment-dialog.component';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MatTooltipModule,
    NgPipesModule,
    CommentDialogComponent,
    CommentDialogDirective,
    ClassificationBannerComponent,
    ClassifyDirective,
    ClassifyShowDirective,
    EmptyStateComponent,
    UserAvatarComponent,
    FullnamePipe,
    ClassificationBannerComponent,
  ],
})
export class CommentListComponent implements OnInit {
  @Input()
  parentType: CommentType;

  @Input()
  editable?: boolean = true;

  @Input()
  deletable?: boolean = false;

  @Input()
  parentId: string;

  loading = false;

  commentService: CommentService;

  commentClosed: { [key: string]: boolean } = {};

  @Input()
  comments: Comment[];

  currentUserId: string;

  @Output()
  countChange = new EventEmitter<number>();

  @AppConfigValue('commentsAttachmentsDisabled')
  commentsAttachmentsDisabled: boolean = true;

  constructor(
    private injector: Injector,
    private alert: AlertService,
    private auth: AuthService
  ) {
    this.currentUserId = this.auth.getUser()?.id as string;
  }

  ngOnInit() {
    this.commentService =
      this.parentType === 'FAR'
        ? this.injector.get<FarCommentService>(FarCommentService)
        : this.injector.get<FVCommentService>(FVCommentService);
    this.refresh();
  }

  refresh() {
    if (this.parentType !== 'FV') {
      this.loading = true;
      this.commentService
        .findByParent(this.parentId)
        .pipe(finalize(() => (this.loading = false)))
        .subscribe((comments) => {
          this.comments = comments;
          this.countChange.emit(this.comments.length);
        });
    }
  }

  onUpdate(comment: Comment) {
    const idx = this.comments.findIndex((c) => c.id === comment.id);
    if (idx < 0) this.comments.push(comment);
    else this.comments[idx] = comment;
  }

  removeComment(comment: Comment) {
    this.alert
      .confirmDelete({
        message: 'Are you sure you would like to delete this comment?',
        title: 'Delete Comment',
        performAction: () => this.commentService.delete(comment.id),
        successMsg: 'Comment successfully deleted.',
      })
      .subscribe((_) => {
        this.comments = this.comments.filter((c) => c.id !== comment.id);
      });
  }
}
