<div
  *ngIf="editable || attachments.length"
  class="d-flex flex-column"
  [class]="buttonStyle ? '' : 'attachments-container'"
  ng2FileDrop="editable"
  [class.file-over-drop]="fileHoverDrop"
  [class.view-attach-files]="showFileList"
  (fileOver)="fileHoverDrop = $event"
  [uploader]="uploader"
>
  <ng-container *ngIf="!buttonStyle">
    <mat-toolbar
      class="view-attachments-toolbar d-flex flex-row"
      *ngIf="attachments?.length || uploading?.length"
    >
      <button
        *ngIf="selectedAttachments.length"
        mat-icon-button
        (click)="clearSelect()"
      >
        <mat-icon svgIcon="close"></mat-icon>
      </button>
      <span
        class="small"
        [ngPlural]="count"
        *ngIf="!selectedAttachments.length && attachments?.length"
      >
        <ng-template ngPluralCase="=1">1 File</ng-template>
        <ng-template ngPluralCase="other">{{ count }} Files</ng-template>
      </span>
      <span class="small" *ngIf="selectedAttachments.length"
        >{{ selectedAttachments.length }} Selected</span
      >
      <span class="col"></span>
      <ng-container *restrictDelete>
        <button
          *ngIf="selectedAttachments.length && deletable"
          mat-icon-button
          color="warn"
          (click)="deleteSelected()"
        >
          <mat-icon svgIcon="delete"></mat-icon>
        </button>
      </ng-container>
      <button matTooltip="Refresh" mat-icon-button (click)="refresh()">
        <mat-icon svgIcon="refresh"></mat-icon>
      </button>
      <button
        *ngIf="editable"
        [disabled]="commentsAttachmentsDisabled"
        mat-icon-button
        color="primary"
        (click)="fileInput.click()"
        matTooltip="Add"
      >
        <mat-icon svgIcon="plus-circle-outline"></mat-icon>
      </button>
    </mat-toolbar>
    <div
      class="
        view-attachments-container
        col
        d-flex
        flex-wrap
        align-items-start
        justify-content-start
        bg-white
      "
      *ngIf="attachments?.length || uploading?.length"
    >
      <div *ngFor="let attachment of attachments" class="file-item-wrapper">
        <figure
          *ngIf="attachment.id as attachmentID"
          [class.selected]="selected[attachmentID]"
          class="
            d-flex
            flex-row
            file-item
            attachment-file
            justify-content-around
          "
          (click)="selectFile(attachment, $event)"
          (dblclick)="openFile(attachment)"
          [stopPropagation]
          matRipple
        >
          <span
            [matMenuTriggerFor]="rootMenu"
            [matMenuTriggerData]="{ attachment: attachment }"
            #menuTrigger="matMenuTrigger"
          ></span>
          <div
            class="d-flex flex-column justify-content-center file-item-details"
            (contextmenu)="
              attachment.status === AttachmentStatus.CLEAN
                ? openMenu($event, menuTrigger)
                : ''
            "
          >
            <ng-container *ngIf="selected[attachment.id]">
              <mat-icon class="file-icon" svgIcon="file-check"></mat-icon>
            </ng-container>
            <ng-container *ngIf="!selected[attachment.id] as attachmentID">
              <mat-icon
                *ngIf="attachment.mimeType as attachmentMimeType"
                class="file-icon"
                aria-hidden="false"
                [mimeType]="attachmentMimeType"
                [matTooltip]="'Virus Scan Status: ' + attachment.status"
                matBadge
                [matBadgeColor]="
                  attachment.status === AttachmentStatus.INFECTED
                    ? 'warn'
                    : 'accent'
                "
                [matBadgeIconHidden]="
                  attachment.status === AttachmentStatus.CLEAN
                "
                matBadgeOverlap="true"
                matBadgePosition="below after"
                [matBadgeIcon]="
                  attachment.status === AttachmentStatus.INFECTED
                    ? 'bug'
                    : 'timer-sand'
                "
              ></mat-icon>
            </ng-container>
            <ng-container *ngIf="attachment.name as attachmentName">
              <span class="file-name" [matTooltip]="attachment.name">{{
                attachment.name
              }}</span>
            </ng-container>
          </div>
        </figure>
      </div>
      <div *ngFor="let uploadFile of uploading" class="file-item-wrapper">
        <figure class="d-flex flex-row file-item uploading-file">
          <div
            class="d-flex flex-column justify-content-center file-item-details"
          >
            <ng-container *ngIf="!uploadFile.item.progress">
              <mat-icon
                *ngIf="uploadFile.item.file.type as fileType"
                [mimeType]="fileType"
                class="file-icon"
                matTooltip="File Pending Upload"
                matBadge
                matBadgeOverlap="true"
                matBadgePosition="below after"
                matBadgeIcon="cloud-upload"
                aria-hidden="false"
              ></mat-icon>
            </ng-container>
            <mat-progress-spinner
              class="file-upload-spinner"
              mode="determinate"
              matTooltip="File Uploading"
              *ngIf="uploadFile.item.progress"
              diameter="48"
              [value]="uploadFile.item.progress"
            >
            </mat-progress-spinner>
            <ng-container *ngIf="uploadFile.attachment.name as attachmentName">
              <span
                class="file-name"
                [matTooltip]="uploadFile.attachment.name"
                >{{ uploadFile.attachment.name }}</span
              >
            </ng-container>
          </div>
        </figure>
      </div>
      <mat-menu #rootMenu="matMenu" [overlapTrigger]="false">
        <ng-template matMenuContent let-attachment="attachment">
          <button
            mat-menu-item
            *ngIf="attachment.status === AttachmentStatus.CLEAN"
            (click)="openFile(attachment)"
          >
            <mat-icon svgIcon="download"></mat-icon>
            <span>Download</span>
          </button>
          <ng-container *restrictDelete>
            <button
              mat-menu-item
              (click)="delete(attachment)"
              *ngIf="deletable"
            >
              <mat-icon svgIcon="delete" color="warn"></mat-icon>
              <span>Delete</span>
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>
    </div>
    <div
      class="upload-attachment-container col"
      *ngIf="!attachments?.length && !uploading?.length && editable"
    >
      <div class="d-flex flex-columm file-upload-wrapper">
        <span class="col"></span>
        <div
          class="file-upload d-flex flex-column justify-content-center"
          *ngIf="editable"
        >
          <h2 class="text-center">Drag files here</h2>
          <h6 class="text-center">Or, if you prefer...</h6>
          <button
            color="primary"
            mat-flat-button
            color="accent"
            (click)="fileInput.click()"
          >
            Choose Files to Upload
          </button>
        </div>
        <span class="col"></span>
      </div>
    </div>
  </ng-container>
  <button
    class="mt-3 w-100"
    style="height: 48px"
    *ngIf="
      !attachments?.length && !uploading?.length && editable && buttonStyle
    "
    mat-raised-button
    [disabled]="commentsAttachmentsDisabled"
    matTooltip="Add Attachment"
    color="primary"
    (click)="fileInput.click()"
  >
    <div class="d-flex align-items-center">
      <span>Add Attachment</span>
      <mat-icon class="ml-2" svgIcon="paperclip"></mat-icon>
    </div>
  </button>

  <input
    #fileInput
    type="file"
    name="image"
    ng2FileSelect
    [attr.multiple]="multiple"
    [uploader]="uploader"
    [attr.accept]="accept"
    style="display: none"
  />
</div>
<app-empty-state
  *ngIf="
    !attachments?.length && !uploading?.length && !editable && !buttonStyle
  "
  [message]="'No Attachments Available'"
>
</app-empty-state>
