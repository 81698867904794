import { Injectable, inject } from '@angular/core';
import { APP_CONFIG, IEnvironment } from '@environments/ienvironment';
import { isUndefined } from 'lodash';

@Injectable({ providedIn: 'root' })
export class AppConfigService {
  private readonly config: IEnvironment = inject(APP_CONFIG);
  private static instance: AppConfigService;
  constructor() {
    AppConfigService.instance = this;
  }

  public static getInstance(): AppConfigService {
    if (!AppConfigService.instance) {
      throw new Error('AppConfigService not initialized');
    }
    return AppConfigService.instance;
  }

  public get<K extends keyof IEnvironment>(
    key: K,
    defaultVal?: IEnvironment[K]
  ): IEnvironment[K] | undefined {
    return isUndefined(this.config[key]) ? defaultVal : this.config[key];
  }

  public set<K extends keyof IEnvironment>(key: K, value: IEnvironment[K]) {
    this.config[key] = value;
  }
}
