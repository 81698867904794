import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import {
  FormsModule,
  NG_VALUE_ACCESSOR,
  NgForm,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import {
  MatFormFieldAppearance,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BaseControlComponent } from '@shared/controls/base-control.component';
import { FvSocialMedia } from '@shared/models/fv-social-media.model';
@Component({
  selector: 'app-social-media-form',
  templateUrl: './social-media-form.component.html',
  styleUrls: ['./social-media-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SocialMediaFormComponent,
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
  ],
})
export class SocialMediaFormComponent extends BaseControlComponent<FvSocialMedia> {
  @ViewChild('socialMediaForm')
  socialMediaForm: NgForm;
  appearance: MatFormFieldAppearance = 'outline';
  types = [
    'Bluesky',
    'FaceBook',
    'Instagram',
    'LinkedIn',
    'Reddit',
    'Telegram',
    'TikTok',
    'Truth Social',
    'WeChat',
    'WhatsApp',
    'X (Twitter)',
    'Youtube',
  ];
  constructor() {
    super();
  }
}
